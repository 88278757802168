import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://df0be03d4143790748b86e97a493b3cf@o315385.ingest.us.sentry.io/4507517759389696",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
});